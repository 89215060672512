export default () => {
  const accordions = document.querySelectorAll('.js-accordion')
  if (accordions.length < 1) {
    return false
  }

  for (const accordion of accordions) {
    const terms = accordion.querySelectorAll('.js-accordion-term')
    if (terms.length < 1) {
      return false
    }

    for (const term of terms) {
      term.addEventListener(
        'click',
        (e) => {
          let closeOtherPanels = true
          let currTerm = e.currentTarget
          closeOtherPanels = currTerm.dataset.closeOther !== 'false'
          let panel = currTerm.nextElementSibling

          for (const anotherTerm of terms) {
            if (anotherTerm !== currTerm && closeOtherPanels) {
                let anotherPanel = anotherTerm.nextElementSibling
                anotherPanel.style.maxHeight = '0px'
                anotherTerm.classList.remove('is-active')
            }
          }

          if (currTerm.classList.contains('is-active')) {
            panel.style.maxHeight = '0px'
            currTerm.classList.remove('is-active')
          } else {
            panel.style.maxHeight = panel.scrollHeight + 'px'
            currTerm.classList.add('is-active')
          }
        },
        false
      )
    }
  }
}
