export default () => {
  const faqQuestions = document.querySelectorAll('.faq_question')
  if (faqQuestions.length === 0) return

  faqQuestions.forEach(term => {
    term.addEventListener('click', function() {
      const targetDescription = this.closest('.faq_question').getAttribute('data-target')
      const desc = document.querySelector(`[data-description="faq-description-${targetDescription}"]`)
      const icon = this.closest('.faq_question').querySelector('.chevron')
      const isActive = desc.classList.contains('open')
      document.querySelectorAll('.faq_question__desc').forEach(otherDesc => {
        otherDesc.classList.remove('open')
        otherDesc.style.maxHeight = '0'
      })

      document.querySelectorAll('.chevron').forEach(otherIcon => {
        otherIcon.classList.remove('rotate-180')
      })

      if (!isActive) {
        desc.classList.add('open')
        icon.classList.add('rotate-180')

        const scrollHeight = desc.scrollHeight
        desc.style.maxHeight = `${scrollHeight}px`
      }
    })
  })
}
